<template>
    <div class="container">
        <div class="container content">
            <div class="question-icon"></div>
            <h1 class="title-not-found">404</h1>
            <p class="sub-not-found">Page not found.</p>
            <p class="sub-not-found sub-text">The page you are looking for does not exist, or has been moved. You can use the links in the sidebar to navigate further.</p>
        </div>
    </div>
</template>
<style>
/* Page CSS */
.title-not-found {
    color: var(--dark-grey);
    text-align: center;
    font-size: 80px;
    font-family: "roboto";
    margin-top: 20px;
    margin-bottom: 20px;
}

.sub-not-found {
    font-family: "roboto";
    text-align: center;
    color: var(--dark-grey);
}

.sub-not-found.sub-text {
    margin-top: 60px;
}

.question-icon {
    height: 80px;
    width: 80px;
    margin: auto;
    margin-top: 40px;
    padding: 10px;
    background-color: var(--light-grey);
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-image: url("../assets/icons/question-outline.svg");
}

</style>
<script>

import axios from 'axios';
export default {
    components: {
          
        },
    title () {
          return `404`
        },
        data() {
        return {

        }
    },
}

</script>
